import React from 'react';
import { Form, Input, Select, Spin } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { Upload } from '@/components';

export default class ProductCategoryForm extends React.PureComponent<{
  initialValues: any
  treeData: any[]
}>{
  private form = React.createRef<FormInstance>();

  state = {
    src: this.props.initialValues?.imageUrl,
    uploading: false,
  };

  renderUploadChildren = () => {
    if (this.state.uploading) return <div>
      <Spin tip="正在上传" indicator={<LoadingOutlined spin/>}/>
    </div>
    if (this.state.src) {
      return <img src={this.state.src} alt="avatar" style={{ width: '100%' }} />
    }
    return <div>
      <PlusOutlined />
      <div className="ant-upload-text">上传图片</div>
    </div>
  }

  render() {
    const { initialValues = {}, treeData = []} = this.props;
    const {src} = this.state;
    const parentId = initialValues.parentId;
    return <div>
      <Form labelCol={{ span: 4 }} ref={this.form} initialValues={initialValues}>
        {
          parentId !== '0' && <Form.Item label="分类图片"
            name="imageId"
            rules={[{
              required: true,
              message: '请上传图片',
            }]}>
            <Upload
              src={initialValues.imageUrl}
              width={150}
              height={150}
              type="category"
              onChange={(data) => {
                this.form.current?.setFieldsValue({
                  imageId: data.imageId,
                });
              }}
            />
          </Form.Item>
        }
        <Form.Item label="分类名称" name="categoryName" rules={[{ required: true, message: '请填写分类名称' }]}>
          <Input placeholder="" />
        </Form.Item>
        <Form.Item label="上级分类" name="parentId"
          rules={[{ required: true, message: '请选择上级分类' }]}>
          <Select style={{width: '100%'}} disabled={!initialValues.categoryId || initialValues.parentId === '0'}>
            {
              initialValues.parentId === '0' &&
              <Select.Option key="0" value="0">
                全部分类
              </Select.Option>
            }
            {treeData.map(item => {
              return <Select.Option key={item.categoryId} value={item.categoryId}>
                {item.categoryName}
              </Select.Option>
            })}
          </Select>
        </Form.Item>
      </Form>
    </div>
  }

  validateFields = () => {
    return this.form.current?.validateFields();
  }
}

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}