import { Button, Dialog, Layout } from "@/components";
import WebApi from "@/web/api";
import WebAuth from "@/web/WebAuth";
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SwapOutlined, VerticalAlignTopOutlined } from "@ant-design/icons";
import { message } from "antd";
import { LibAuthCode } from "lib/Auth";
import { LibICategoryItem } from "lib/interface/ProductInterface";
import React from "react";
import ProductCategoryForm from "./ProductCategoryForm";
const style = require('./product_category.less');

interface IState {
  loading: boolean;
  addDialog: boolean;
  updateDialog: boolean;
  sortDialog: boolean;
  categoryList: LibICategoryItem[];
  updateCategory: LibICategoryItem | null;
  newCategory: {parentId: string} | null;
  sortParentId: string;
  sortCategoryList: LibICategoryItem[];
}

export default class ProductCategoryPage extends React.PureComponent<RouterPropsType, IState> {

  private addForm = React.createRef<ProductCategoryForm>();
  private updateForm = React.createRef<ProductCategoryForm>();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      addDialog: false,
      sortDialog: false,
      updateDialog: false,
      updateCategory: null,
      newCategory: null,
      sortParentId: '',
      sortCategoryList: [],
      categoryList: []
    };
  }

  componentDidMount() {
    this.getCategoryList();
  }

  getCategoryList = (callback?: Function) => {
    WebApi.category_list({withImage: true}).then(data => {
      this.setState({
        categoryList: data as any
      });
      callback && callback();
    })
  }

  render() {
    return <Layout.Page className="product-category">
      <Layout.Toolbar title="商品分类">
        <Button type="primary" onClick={() => {
          this.setState({
            newCategory: {
              parentId: '0',
            },
            addDialog: true,
          });
        }}>新增一级分类</Button>
        <Button type="default"
          icon={<SwapOutlined/>}
          authCode={LibAuthCode.category.update}
          onClick={() => {
          this.setState({
            sortDialog: true,
            sortParentId: '0',
            sortCategoryList: [...this.state.categoryList]
          });
        }}>一级分类排序</Button>
        <Button.Refresh onClick={() => {
          this.getCategoryList(() => {
            message.success('刷新成功');
          });
        }}/>
      </Layout.Toolbar>
      <Layout flex1 scrollY>
        {
          this.state.categoryList.map((item, index) => {
            return <div key={item.categoryId} className="product-category-item">
              <div className="product-category-item-toolbar">
                <div className="product-category-item-name">
                  <i className="iconfont icon-leimupinleifenleileibie2"></i>
                  {item.categoryName}
                </div>
                <div className="product-category-item-action">
                  <Button type="default"
                    icon={<SwapOutlined />}
                    authCode={LibAuthCode.category.update}
                    onClick={() => {
                      this.setState({
                        sortDialog: true,
                        sortParentId: item.categoryId,
                        sortCategoryList: [...item.children]
                      });
                  }}>排序</Button>
                  <Button.Edit authCode={LibAuthCode.category.update} onClick={() => {
                    this.setState({
                      updateDialog: true,
                      updateCategory: item,
                    });
                  }}/>
                  <Button.Delete authCode={LibAuthCode.category.delete} onClick={() => {
                    this.clickDelete(item);
                  }}/>
                </div>
              </div>
              <div className="product-category-child-list">
                {
                  item.children.map(child => {
                    return <div key={child.categoryId} className="product-category-child-item">
                      <div className="product-category-child-item-img">
                        <img src={child.imageUrl}/>
                      </div>
                      <div className="product-category-child-item-name">{child.categoryName}</div>

                      {
                        WebAuth.hasAuth(LibAuthCode.category.update) &&
                        <div className="product-category-child-item-editor">
                          <i className="iconfont icon-bianji" onClick={() => {
                            this.setState({
                              updateDialog: true,
                              updateCategory: child,
                            });
                          }}/>
                          <i className="iconfont icon-delete" onClick={() => {
                            this.clickDelete(child);
                          }}/>
                        </div>
                      }
                    </div>
                  })
                }
                {
                  WebAuth.hasAuth(LibAuthCode.category.create) &&
                  <div className="product-category-child-item add-child-button" onClick={() => {
                    this.setState({
                      newCategory: {
                        parentId: item.categoryId,
                      },
                      addDialog: true,
                    });
                  }}>
                    <PlusOutlined/>
                  </div>
                }
              </div>
            </div>
          })
        }
      </Layout>

      {/* 新增分类*/}
      <Dialog
        title="新增商品分类"
        visible={this.state.addDialog}
        full={false}
        onOk={this.clickAddDialogOk}
        confirmLoading={this.state.loading}
        okButtonProps={{
          disabled: this.state.loading
        }}
        onCancel={() => this.setState({ addDialog: false })}>
        <ProductCategoryForm
          ref={this.addForm}
          treeData={this.state.categoryList} initialValues={this.state.newCategory}
        />
      </Dialog>

        {/* 修改分类 */}
        <Dialog
          title="修改商品分类"
          visible={this.state.updateDialog}
          full={false}
          okButtonProps={{
            disabled: this.state.loading
          }}
          confirmLoading={this.state.loading}
          onOk={this.clickUpdateDialogOk}
          onCancel={() => this.setState({ updateDialog: false })}
        >
          <ProductCategoryForm
            ref={this.updateForm}
            treeData={this.state.categoryList}
            initialValues={this.state.updateCategory}
          />
        </Dialog>

        {/* 排序 */}
        <Dialog title="排序"
          visible={this.state.sortDialog}
          full={false}
          okButtonProps={{
            disabled: this.state.loading
          }}
          confirmLoading={this.state.loading}
          onOk={this.clickSortDialogOk}
          onCancel={() => this.setState({ sortDialog: false })}
        >
          <div>
            {
              this.state.sortCategoryList.map((item, index, array) => {
                const list = this.state.sortCategoryList || [];
                return <div className="product-category-sort">
                  {item.categoryName}
                  <div>
                    {/* <Button type="default"
                      icon={<VerticalAlignTopOutlined/>}
                      disabled={index === 0}
                      onClick={() => {
                        
                      }}>
                      置顶
                    </Button> */}
                    <Button type="default" icon={<ArrowUpOutlined />}
                      disabled={index === 0}
                      onClick={() => {
                        list[index] = list.splice(index - 1, 1, list[index])[0];
                        this.setState({
                          sortCategoryList: [...list],
                        });
                      }}>上移</Button>
                    <Button type="default" icon={<ArrowDownOutlined />}
                      disabled={index == array.length - 1}
                      onClick={() => {
                        list[index] = list.splice(index + 1, 1, list[index])[0];
                        this.setState({
                          sortCategoryList: [...list],
                        });
                      }}>下移</Button>
                  </div>
                </div>
              })
            }
          </div>
        </Dialog>
    </Layout.Page>
  }

  private clickAddDialogOk = () => {
    this.addForm.current?.validateFields()?.then((values: any) => {
      this.setState({
        loading: true,
      });
      WebApi.category_create({
        categoryName: values.categoryName,
        imageId: values.imageId,
        parentId: values.parentId,
      })
      .then(() => {
        showSuccess('添加成功');
        this.getCategoryList();
        this.setState({ addDialog: false });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      })
    })
  }

  // 删除分类
  private clickDelete = item => {
    if (Array.isArray(item.children) && item.children.length > 0) {
      showFail('当前分类下有二级分类，请先删除二级分类！');
      return;
    }
    Dialog.confirm({
      content: `确认删除 “${item.categoryName}” 分类？`,
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        WebApi.category_delete({
          categoryId: item.categoryId
        })
        .then(() => {
          showSuccess('删除成功');
          this.getCategoryList();
        })
      },
    });
  };

  private clickUpdateDialogOk = async () => {
    try {
      const values: any = await this.updateForm.current?.validateFields();
      this.setState({
        loading: true,
      });
      WebApi.category_update({
        categoryId: this.state.updateCategory?.categoryId,
        ...values,
      })
      .then(() => {
        showSuccess('修改成功');
        this.getCategoryList();
        this.setState({
          updateDialog: false,
        });
      }).finally(() => {
        this.setState({
          loading: false,
        });
      })
    } catch (e) {
      console.log(e);
    }
  }

  private clickSortDialogOk = () => {
    this.setState({
      loading: true,
    });
    WebApi.category_sort({
      parentId: this.state.sortParentId,
      categoryIdList: this.state.sortCategoryList.map(item => item.categoryId)
    })
    .then(() => {
      showSuccess.save();
      this.setState({
        sortDialog: false,
      });
      this.getCategoryList();
    })
    .finally(() => {
      this.setState({
        loading: false,
      });
    });
  }
}